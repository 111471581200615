<template>

  <div>
    <!-- <b-overlay :show="showOverlay" no-wrap></b-overlay> -->
    <b-container fluid class="tw-bg-white tw-my-5 tw-py-5">
    <b-row>
      <b-col>
        <h3>
          {{ form.template ? "Templates" : "Exercises" }}
        </h3>
      </b-col>
      <b-col cols="auto">
        <b-form-input v-model="form.keyword" placeholder="Search" @keyup="page = 1; find(true)"></b-form-input>
      </b-col>
      <b-col cols="auto" v-if="$store.getters['user/isExerciseController']">
        <b-form-select
            id="template"
            v-model="form.template"
            :options="[{value: false, text: 'Exercises'}, {value: true, text: 'Templates'}]"
        ></b-form-select>
      </b-col>
      <b-col cols="auto" v-if="$store.getters['user/isExerciseController']">
        <b-form-select
            id="status"
            v-model="form.status"
            :options="statusOptions"
        ></b-form-select>
      </b-col>
      <b-col cols="auto" v-if="$store.getters['user/isExerciseController']">
        <b-button
          variant="secondary"
          id="create-blank-exercise-popover"
        >
          Create Blank Exercise
        </b-button>
      </b-col>
      <b-col cols="auto" v-if="$store.getters['user/isExerciseController']">
        <b-button
          variant="primary"
          id="create-exercise-template-popover"
        >
          Create Template
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="tw-pt-5">
        <b-table hover :items="filteredList" :fields="fields">

          <template v-slot:cell(actions)="data">
            <b-button v-if="$store.getters['user/isExerciseController'] && data.item.status != 'archived'" title="Edit exercise." squared
              variant="outline-secondary" :to="{ name: 'exercise-form-update', params: { exerciseId: data.item._id } }">
              <i class="fas fa-pen"></i>
            </b-button>
            

            <b-button
              title="Build Exercise"
              squared
              variant="outline-primary"
              class="ml-2"
              @click="showOverlay = true; $router.push({ name: 'sim', params: { id: data.item._id } });"
              v-if="['draft', 'open'].indexOf(data.item.status) >= 0 && $store.getters['user/isExerciseController']"
            >
              <i class="fas fa-hammer"></i>&nbsp;&nbsp;Build
            </b-button>

            <b-button
              title="Play Exercise"
              squared
              variant="outline-primary"
              class="ml-2"
              @click="showOverlay = true; $router.push({ name: 'sim', params: { id: data.item._id } });"
              v-if="['open','playing', 'paused'].indexOf(data.item.status) >= 0"
            >
              <i class="fas fa-play"></i>&nbsp;&nbsp;Play
            </b-button>

            <b-button
              title="Review Exercise"
              squared
              variant="outline-primary"
              class="ml-2"
              @click="showOverlay = true; $router.push({ name: 'sim', params: { id: data.item._id } });"
              v-if="['stopped', 'cancelled', 'archived'].indexOf(data.item.status) >= 0"
            >
              <i class="fas fa-reply-all"></i>&nbsp;&nbsp;Analyze
            </b-button>



            <b-button
              v-if="$store.getters['user/isExerciseController'] && !data.item.template && data.item.status == 'draft'"
              title="Open Exercise"
              squared
              variant="outline-primary"
              class="ml-2"
              @click="setStatus(data.item._id, 'open')"
            >
              Open Exercise
            </b-button>

            <b-button
              v-if="$store.getters['user/isExerciseController'] && ['draft', 'open', 'stopped'].indexOf(data.item.status) >= 0"
              title="Archive"
              squared
              variant="outline-primary"
              class="ml-2"
              @click="setStatus(data.item._id, 'archived')"
            >
              Archive
            </b-button>

            <b-dropdown :id="`dropdown-${data.item._id}`" right class="tw-ml-2" v-if="$store.getters['user/isExerciseController']">
              <template #button-content>
                Copy Options &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </template>
              <b-dropdown-item @click="copy(data.item._id, true)">New Template</b-dropdown-item>
              <b-dropdown-item @click="copy(data.item._id, false)">New Exercise</b-dropdown-item>
            </b-dropdown>
          </template>

          <template v-slot:cell(status)="data">
            <ExerciseStatus :status="data.value" />
          </template>

          <template v-slot:cell(simTime)="data">
            <h3>
              <b-badge class="tw-w-full">
                <i class="fas fa-clock"></i>
                {{ secondsToFormattedSendTime(data.item.currentTime) }}
              </b-badge>
            </h3>
          </template>

          <template v-slot:cell(name)="data">
            <h3>
              {{ data.value.substr(0, 30) }}
              <span v-if="data.value.length > 30">...</span>
            </h3>
          </template>

          <template v-slot:cell(exerciseProgress)="data">
            <ExerciseProgress :exercise="data.item" :current-time="data.item.currentTime" />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="">
        <base-button @click="find()" :loading="showOverlay">
          <!-- <b-spinner v-if="!showOverlay"></b-spinner> -->
          <!-- <b-spinner label="Spinning"></b-spinner> -->
          Load More
        </base-button>
      </b-col>
    </b-row>
    <b-row v-if="$store.getters['user/isExerciseController']">
      <b-col>
        <b-popover target="create-blank-exercise-popover" title="" triggers="click" placement="bottomleft">
          <b-container fluid>
            <b-row class="my-2">
              <b-col>
                <b-form-input id="input-large" size="lg" v-model="name" placeholder="Exercise Name"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col>
                <b-button block @click="create(false)" :disabled="!name.trim()">
                  Create
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-popover>

        <b-popover target="create-exercise-template-popover" title="" triggers="click" placement="bottomleft">
          <b-container fluid>
            <b-row class="my-2">
              <b-col>
                <b-form-input id="input-large" size="lg" v-model="name" placeholder="Template Name"></b-form-input>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col>
                <b-button variant="primary" block @click="create(true)" :disabled="!name.trim()">
                  Create
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-popover>
      </b-col>
    </b-row>
  </b-container>
  </div>
</template>
<script>

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
  mixins: [SocketIOMixin],
  components: {},
  data() {
    return {
      fields: ["name", "status", "actions"],
      page: 1,
      form: {keyword: '', template: false, status: 'open,playing,paused'},
      name: '',
      statusOptions: [
        {value: 'draft,open,playing,paused,stopped', text: 'ALL Statuses'},
        {value: 'draft', text: 'Draft'},
        {value: 'open', text: 'Open'},
        {value: 'playing', text: 'Playing'},
        {value: 'paused', text: 'Paused'},
        {value: 'stopped', text: 'Stopped'},
        {value: 'archived', text: 'Archived'}
      ],
      showOverlay: false
    };
  },
  props: {
    myOptions: {
      type: Object,
      required: false,
    }
  },
  computed: {
    filteredList() {
      const that = this;
      return this.itemList.filter((item) => {
        if (that.$store.state.user.user.systemRoles.length == 0 && ['draft', 'stopped', 'archived'].indexOf(item.status) >= 0) {
          return false;
        }

        return true;
      });
    }
  },
  watch: {
    'form.template': function (val) {
      this.page = 1;
      this.itemList = [];

      if(val) {
        this.fields = ["name", "actions"];
      } else {
        this.fields = ["name", "status", "actions"];
      }

      this.find();
    },
    'form.status': function () {
      this.page = 1;
      this.itemList = [];
      this.find();
    },
  },
  created() {
    this.itemList = [];
    this.page = 1;
  },
  mounted() {
    const that = this;
    that.socketIOSubscribeLibraryHelper({
      name: `exercise`
    });

    setTimeout(() => {
      if(that.$store.getters['user/isExerciseController']) {
        if(that.$route.query.template == "true") {
          that.form.template = true;
        }

        if(that.$route.query.status) {
          that.form.status = that.$route.query.status;
        } else {
          that.form.status = 'draft,open,playing,paused,stopped';
        }
      }
    }, 1000);

    that.find();
  },
  methods: {
    async find(clear = false) {
      const that = this;
      
      that.showOverlay = true;

      const query = _.extend({}, that.myOptions);
      query.page = that.page++;
      query.template = that.form.template;
      query.status = that.form.status;
      query.keyword = that.form.keyword.trim();

      const response = await that.$http.get("/exercise", {
        params: query
      });

      if(clear) {
        that.itemList = [];
      }

      that.itemList = that.itemList.concat(response.data);

      // Go back one page if we don't find anything.
      if (!response.data.length) {
        that.page--;
      }

      that.showOverlay = false;
    },
    async create(template) {
      const that = this;

      if (!that.name.trim()) {
        return;
      }

      const response = await that.$http.post("/exercise/", {
        name: that.name,
        template: template
      });

      that.$notify({
        message: `Exercise '${that.name}' created.`,
        timeout: 10000,
        type: "success",
      });

      that.$router.push({
        name: "exercise-form-update",
        params: { exerciseId: response.data._id },
      });
    },
    async copy(id, template) {
      const that = this;

      const response = await that.$http.post("/exercise-copy", {
        id: id,
        template: template
      });

      that.$router.push({
        name: "exercise-form-update",
        params: { exerciseId: response.data._id },
      });
    },
    async setStatus(id, status) {
      const that = this;
      that.$bvModal.msgBoxConfirm(`Are you sure you want to change exercise status?`)
          .then(async (val) => {
            if(val) {
              const response = await that.$http.patch(`/exercise/${id}`, {
                status: status
              });

              that.$notify({
                text: `Exercise status updated.`,
                duration: 10000,
                type: "success",
              });
            }
          });
    },
  },
};
</script>
<style>

</style>
